body,
html,
#root,
.App {
  height: 100%;
  overflow: hidden;
  background-color: black;
}

* {
  margin: 0;
  padding: 0;
}



/* App bar*/
.app-bar {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  background-color: #333;
  height: 50px;
  z-index: 1;
  text-align: center;
  line-height: 50px;
  color: white;
  display: flex;
  justify-content: space-between;
  padding: 0 1rem;
}

.app-bar-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;

}

.app-bar-btn {
  background-color: transparent;
  border: 1px solid white;
  color: white;
  padding: 10px;
}

/* App body */
.app-body {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  flex: 1;
  gap: 1rem;

}

.create-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 2rem;
  gap: 1rem;
  height: 100%;
}

.create-heading {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color: white;
}



.create-input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
}

.create-btn {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  background-color: #333;
  color: white;
  cursor: pointer;
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* Game */
.game-container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(6, 1fr);
  grid-gap: 1rem 0.5rem;
  margin: 1rem;
  color: white;

}

.game-keyboard {
  position: sticky;
  bottom: 0;
  right: 0;
  left: 0;
}

.game-over-board {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  height: 100%;
  background-color: #333;
  color: white;
  padding: 5rem 0;
  width: 100%;
  grid-column: span 5;

}

.key-disabled {
  opacity: 0.3;
  background-color: black !important;
  color: grey;
}

.box {
  width: 100%;
  height: 50px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #333;
  color: white;
  text-align: center;
  line-height: 50px;
  font-size: 2rem;
  font-weight: bold;
}

.box.green {
  background-color: green;
}

.box.yellow {
  background-color: rgb(197, 197, 0);
}

/* ----------------------------------------------
  Generated by AnimatiSS
  Licensed under FreeBSD License
  URL: https://xsgames.co/animatiss
  Twitter: @xsgames_
---------------------------------------------- */

.rotate-horizontal-center {
  animation: rotate-horizontal-center 1s
}

@keyframes rotate-horizontal-center {
  0% {
    transform: rotateX(0)
  }

  100% {
    transform: rotateX(-360deg)
  }
}

.hidden {
  display: none;
}